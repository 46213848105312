import React, { Component } from 'react';

class Nav extends Component {
    constructor(props) {
        super(props);

        this.back = this.back.bind(this);
    }

    back() {
        
    }

    render(props) {
        return (
            <div className="nav" />
        )
    }
}

export default Nav
